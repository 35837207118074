import 'magnific-popup';
import moment from 'moment';

jQuery( document ).ready(function($) {
    AOS.init({
        duration: 1000,
        once: true
    });

    $('.videos-slider').slick({
      prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-chevron-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="fal fa-chevron-right"></i></button>'
    });

    $('#apple-btn').magnificPopup({
      items: {
          src: '#apple-popup',
          type: 'inline'
      }
    });

    let SMEPreSave = {
        state: null,
        spotify_user: null,
        url_vars: [],
        getURLVars: function (callback, form_id) {
          let hash;
          let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
          for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            this.url_vars.push(hash[0]);
            this.url_vars[hash[0]] = hash[1];
              }
          this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
          this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
          this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
          this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
          this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
          if (typeof callback === 'function') {
            callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
          }
        }
      };
      
      /* String state
       *  The status of the pre-save.
       *  Int ae_member_id
       *  The Appreciation Engine member ID. This can be passed to the forms processor.
       *  String spotify_user
       *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
       *  String deezery_user
       *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
       */
      SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
        // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
        if (state === 'success' || state === 'thank-you') {
            $.magnificPopup.open({
                items: {
                  src: '#thank-you',
                  type: 'inline'
                }
            });
        }
      });


      // Tour
    $.ajax( {
      url: 'https://rest.bandsintown.com/artists/Nardo%20Wick/events?app_id=45PRESS_nardo-wick',
      method: 'GET',
      dataType: 'json',
      xhrFields: {
          withCredentials: false
      },
      error: () => {
      },
      success: data => {
          const events = $( '#events' );
          let html = '';
          if ( data.length ) {
              for ( let event of data ) {
                  const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                  html += '<div class="event" data-bit-id="' + event.id + '">';
                  html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
                  html += '<div class="event-venue-location">';
                  html += '<div class="event-venue">' + event.venue.name + '</div>';
                  html += '<div class="event-location">' + event_location + '</div>';
                  html += '</div>';
                  html += '<div class="event-links">';
                  html += '<a href="' + event.url + '" target="_blank" class="btn">Tickets</a>';
                  html += '</div>';
                  html += '</div>';
              }
              events.html( html );
          } else {
              events.html( 'No upcoming events.' );
          }
      }
  } );
});

// Wrap your logic in the musickitloaded event

document.addEventListener('musickitloaded', function () {
  let AmkPlaylists = [];
  let AmkAlbums = ["1598017272"];
  // Create a new SMEAppleMusic object with your parameters
  let sm = new SMEAppleMusic({
    ae: {
      ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
      brand_id: '4626489',
      segment_id: '1216333',
      activities: '{"actions":{"presave":76794},"mailing_list_optins":{"a0S0800000W7LBSEA3":76223}}'
    },
    am: {
      dev_token: '',
      save_mode: 'library',
      custom_playlist_name: '',
      resources: {
        playlists: AmkPlaylists,
        albums: AmkAlbums
      }
    },
    sf: {
      form: '134880',
      default_mailing_list: 'a0S0800000W7LBSEA3'
    },
    smf: {
      campaign_id: '134880',
      campaign_key: '746fd7adc14a11d243ca9a680d42702d'
    }
  });
  document.getElementById('apple_music_form').addEventListener('submit', function (e) {
    e.preventDefault();
    // Grab the email address value
    const email = document.getElementById('apple_music_email_address').value;
    // Pass the email address and opt in status
    // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
    sm.doActions(email, true).then(function (res) {
      // Complete - handle your logic here
      $.magnificPopup.open({
        items: {
          src: '#thank-you',
          type: 'inline'
        }
      });
    }).catch(function (err) {
      // Error - handle your logic here
    });
  });


  // Form
  $("#nardo-wick-nl-form").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
        type: "POST",
        url: "https://subs.sonymusicfans.com/submit",
        dataType: "json",
        data: data,
        xhrFields: {
            withCredentials: false
        },
        success: function (data) {
            // Do things on successful submission.
            $('#nardo-wick-nl-form').hide();
            $('.form-success').show();
        },
        error: function (err) {
            // Do things when submission has an error.
            $('.form-error').show();
        }
    });
});
});